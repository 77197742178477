<template>
  <div>
    <div class="lastbuy">
      <template v-for="(item, index) in comObj.data" >
        <div
          v-if="item.changeSeckill"
          class="lastbuy-item"
          :key="index"
          @click="selectModule(item, index)">
          <div class="hot-goods">
            <span>抢购</span>
            <div class="hot-goods-time">
              <div>10点场</div>9:50:52
            </div>
          </div>
          <div class="lastbuy-item-left-con">
            <div class="lastbuy-bot-item">
              <div class="lastbuy-bot-item-img">
                <img :src="item.oneImage" alt />
              </div>
            </div>
            <div class="lastbuy-bot-item">
              <div class="lastbuy-bot-item-img">
                <img :src="item.oneImage" alt />
              </div>
            </div>
          </div>
        </div>

        <!--  -->
        <div
        v-else
        class="lastbuy-bot-item-two"
        :key="index"
        @click="selectModule(item, index)"
      >
        <div class="lastbuy-bot-item-title">
          <span class="lastbuy-bt" :style="{color: item.nameColor}">{{item.name}}</span>
          <span class="lastbuy-btpay" :style="{color: item.titleColor}">{{item.title}}</span>
        </div>
        <div class="lastbuy-bot-item-two-img">
          <img :src="item.oneImage" alt />
          <img :src="item.twoImage" alt />
        </div>
        <div class="hintCont">
          <div class="lft" :style="{color: item.leftHintColor}">{{item.leftHint}}</div>
          <div class="rgt" :style="{color: item.rgtHintColor}">{{item.rgtHint}}</div>
        </div>
      </div>
      </template>
    </div>
  </div>
</template>
<script>
import { mapGetters } from "vuex";
export default {
  computed: {
    ...mapGetters(["merketObj"])
  },
  props: ["comObj"],
  data() {
    return {};
  },
  mounted() {
    console.log(this.merketObj);
  },
  methods: {
    selectModule(item, index) {
      console.log(item, index);
      this.merketObj.selectMoudule = item;
      this.merketObj.selectIndex = index;
      console.log(this.merketObj);
      this.$store.commit("merket/Set_obj", this.merketObj);
      // 更新vuex里面的PageJSON
      this.$store.dispatch("apply/PageJSONUpData");
      // 更新父组件中的PageJSON5
      this.$emit("updataNowPageJSON");
    }
  }
};
</script>
<style lang="scss" scoped>
.lastbuy {
  width: 95%;
  height: auto;
  display: flex;
  flex-wrap: wrap;
  background: rgba(255, 255, 255, 1);
  border-radius: 5px;
  margin: 0 auto;
  font-weight: 500;
  font-size: 16px;
  padding: 5px;
  margin: 0 auto;
  .lastbuy-item {
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;

    .hot-goods {
      display: flex;
      align-items: center;
      justify-content: space-between;

      span {
        display: inline-block;
        padding: 0 6px;
      }

      .hot-goods-time {
        flex: 1;
        display: flex;
        align-items: center;
        border-radius: 9px;
        color: #ff8000;
        font-size: 14px;
        border: 1px solid rgba(255, 128, 0, 1);

        div {
          color: #ffffff;
          padding: 0 10px;
          border-radius: 9px;
          margin-right: 6px;
          border: none;
          margin-left: -1px;
          background: rgba(255, 128, 0, 1);
        }
      }
    }
  }
  .lastbuy-item {
    // border-top: 1px solid #dedede;
    width: 50%;
    height: 50%;
    display: flex;
    flex-direction: column;
    .hot-goods {
      display: flex;
      align-items: center;
      justify-content: space-between;
      span {
        display: inline-block;
        padding: 0 6px;
      }
      .hot-goods-time {
        flex: 1;
        display: flex;
        align-items: center;
        border-radius: 9px;
        color: #ff8000;
        border:1px solid rgba(255, 128, 0, 1);
        div {
          color: #ffffff;
          padding: 0 10px;
          border-radius: 9px;
          margin-right: 6px;
          border: none;
          margin-left: -1px;
          background: rgba(255, 128, 0, 1);
        }
      }
    }
  }
  .lastbuy-item-left-con {
    margin-top: 10px;
    width: 100%;
    height: 70px;
    display: flex;
    justify-content: space-between;

    .lastbuy-bot-item {
      width: 50%;
      height: auto;
      display: flex;
      text-align: center;
      flex-direction: column;
      align-items: center;
      padding: 0 6px;

      .lastbuy-bot-item-img {
        width: 100%;
        height: 100%;

        img {
          border-radius: 10px;
          width: 100%;
          height: 100%;
        }
      }

      .iconrenminbi_o {
        font-size: 12px;
      }

      .newPay {
        font-size: 12px;
        color: #ff8000;

        .iconrenminbi_o {
          font-size: 12px;
        }
      }

      .oldPay {
        font-size: 12px;
        color: #9f9f9f;
        margin-left: -5px;
        text-decoration: line-through;

        .iconrenminbi_o {
          font-size: 6px;
        }
      }
    }
  }

  .lastbuy-bot-item-two:nth-child(3),
  .lastbuy-bot-item-two:nth-child(4) {
    padding-top: 6px;
  }
  .lastbuy-bot-item-two:nth-child(1),
  .lastbuy-bot-item-two:nth-child(2) {
    border: 0px;
  }
  .lastbuy-bot-item-two {
    border-top: 1px solid #dedede;
    width: 50%;
    height: 44%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-bottom: 10px;
    .lastbuy-bot-item-title {
      padding-left: 6px;

      .lastbuy-bt {
        color: #333333;
        flex-wrap: Medium;
      }

      .lastbuy-btpay {
        font-size: 12px;
        flex-wrap: Medium;
        color: #08a7d1;
      }
    }

    .lastbuy-bot-item-two-img {
      width: 100%;
      height: 72.5px;
      display: flex;
      justify-content: space-between;
      margin: 8px 0;
      padding: 0 5px;
      img {
        width: 47%;
        height: 100%;
        border-radius: 10px;
      }
    }

    .hintCont {
      // margin-top: -5px;
      text-align: center;
      font-size: 12px;
      display: flex;
      justify-content: space-between;
      div {
        flex: 1;
        overflow: hidden;
      }
    }
  }
}
</style>
